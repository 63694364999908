import React from 'react'
import './Instructor.css'
import { team } from "../../Data/Instructor"
import 'remixicon/fonts/remixicon.css'
import image1 from '../../assest/icons8-student-96.png'

const Instructor = () => {
  return (
    <>
    <div className="teacher">
        <h1>Our Expert Instructor</h1>
        <div className="Instructor">
        {team.map((val) => {
                return (
                  <div className='man'>
                    <div className='pict'>
                      <img src={val.cover} alt='' />
                    </div>
                    <div className='maninfo'>
                      <h2>{val.name}</h2>
                      <p>{val.work}</p>
                      <div className='End'>
                      <p><img src={image1} /> {val.student}</p>
                      <p><i class="ri-star-fill"></i>{val.etoile}</p>
                      <p>{val.experince}</p></div>
                    </div>
                  </div>
                )
              })}
        </div>
    </div>
    
    </>
  )
}

export default Instructor