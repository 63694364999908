import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div className='header'>
        <div className="logo">
            <h1>Bra<span>Vaa</span></h1>

        </div >
        <div className='secend'>
        <div className="bar">
        <ul>
            <li><Link to="/" >Home</Link></li>
            <li><Link to="/About" >About</Link></li>
            <li><Link to="/Course" >Course</Link></li>
            <li><Link to="/Instructor" >Instructor</Link></li>
        </ul>
        </div>
    <div className="cnx">
        <ul>
            <li>Login</li>
        <li>Register</li>
        </ul>
    </div></div>
    </div>
  )
}

export default Header