import React from 'react'
import './Course.css'
import CourseCart from './CourseCart'


const Course = () => {
  return (
    <>
   <CourseCart />
    
    
    
    </>
  )
}

export default Course