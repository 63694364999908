import logo from './logo.svg';
import './App.css';
import Header from './Component/Header/Header';
import Home from './Component/Home/Home';
import About from './Component/About/About';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Routes ,Route } from 'react-router-dom';
import Course from './Component/Course/Course';
import Instructor from './Component/Instructor/Instructor';
import Footer from './Component/Footer/Footer';

function App() {
  return (
    <div className="App">
     
      <Router>
      <Header/>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Course" element={<Course />} />
          <Route path="/Instructor" element={<Instructor />} />
      </Routes>
      <Footer />
      </Router>
    </div>
  );
}

export default App;
