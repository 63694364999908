import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <>
    <div className="final">
        <h1>Subscribe to get our Newsletter</h1>
        <div className="email">
            <input type="text" placeholder='Your email'/>
            <button className='btnnn'>Subscribe</button>
        </div>
        <div className="result">
            <div className="lewla">
            <div className="logo">
            <h1>Bra<span>Vaa</span></h1>
            </div >
        <p>Premium template designed for GYMs and Fitness Coaches If you are looking.</p>
            <div className="social">
            <i class="ri-facebook-fill"></i>
            <i class="ri-youtube-fill"></i>
            <i class="ri-spotify-fill"></i>
            <i class="ri-instagram-fill"></i>
            </div>
            </div>
            <div className="tanya">
                <h1>Our Campany</h1>
                <ul>
                    <li>Home</li>
                    <li>About Us</li>
                    <li>All Instructors</li>
                    <li>Course</li>
                    <li>Contact Us</li>
                </ul>
            </div>
            <div className="talta">
            <h1>Useful Links</h1>
                <ul>
                    <li>Payment & Tx</li>
                    <li>Terms Of Service</li>
                    <li>Privacy Policy</li>
                    <li>Account</li>
                    <li>Contact Us</li>
                </ul>
            </div>
        </div>
    </div>
    
    </>
  )
}

export default Footer