import React from 'react'
import './About.css'
import Aboutcart from './Aboutcart'
const About = () => {
  return (
    <>
<Aboutcart />
   
    </>
  )
}

export default About