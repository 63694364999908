import img1 from '../assest/c1.png'
import img2 from '../assest/c2.png'
import img3 from '../assest/c3.png'

export const coursesCard = [
    {
      id: 1,
      cover: img1,
      coursesName: "Introducing to Software Engineering",
      /*courTeacher: [
        {
          dcover: "./images/back.webp",
          name: "by John Smith",
          totalTime: "50 lectures (190 hrs)",
        },
      ],*/
      desc:"Mode pumps inspiration skirt oonsumer halter limited imprint braiding.",
      priceAll: "$100.00",
    },
    {
      id: 2,
      cover: img2,
      coursesName: "Enhancing Adobe Photoshop CC 2020 Skills",
     
      desc:"Mode pumps inspiration skirt oonsumer halter limited imprint braiding.", 
      priceAll: "$200.00",
    },
    {
      id: 3,
      cover: img3,
      coursesName: "HTML, CSS, and Javascript for Web Developers",
      
      desc:"Mode pumps inspiration skirt oonsumer halter limited imprint braiding.",
      priceAll: "$50.00",
    },
    {
      id: 4,
      cover: img3,
      coursesName: "Introducing to Programming with WordPress",
      
      desc:"Mode pumps inspiration skirt oonsumer halter limited imprint braiding.",
      priceAll: "$30.00",
    },
    {
      id: 5,
      cover: img2,
      coursesName: "Introducing to Programming with ReactJS",
      
      desc:"Mode pumps inspiration skirt oonsumer halter limited imprint braiding.",
      priceAll: "$300.00",
    },
    {
      id: 6,
      cover: img1,
      coursesName: "Learn Frontend Programming Language",
      
      desc:"Mode pumps inspiration skirt oonsumer halter limited imprint braiding.",
      priceAll: "$500.00",
    },
    
  ]