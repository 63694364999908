import React from 'react'
import './About.css'
import { homeAbout } from "../../Data/Data"
import Student from "../../assest/11.jpg"
import man from "../../assest/2022-08-25.png"
import Numbercounter from 'number-counter'

const Aboutcart = () => {
  return (
    <>
    <div className="About">
        <div className="color">
    <div className='redd'></div>
    <div className='color2'></div>
    </div>

    <div className="tit">
<h1>Our Learning Opportunity</h1>
    </div>
    <div className="details">
    <div className='right row'>
            <div className='items'>
              {homeAbout.map((val) => {
                return (
                  <div className='item flexSB'>
                    <div className='img'>
                      <img src={val.cover} alt='' />
                    </div>
                    <div className='text'>
                      <h2>{val.title}</h2>
                      <p>{val.desc}</p>
                      <a>{val.more}</a>
                    </div>
                  </div>
                )
              })}
            </div>
</div>
    </div>
    <div className='bigphoto'>
    <img src={Student} /></div>
  </div>
<div className="seceond2">
  <div className="imgg">
    <img src={man} />
  </div>
  <div className="info">
    <h1>We Help Students Grow Their Career</h1>
    <p>Price pattern glossy. Waistline ensemble trend pumps elegant petticoat sewing pretporter value yooung availability original glossy handbag influence</p>
  <div className="number">
    <div className="nbr">
      <span> <Numbercounter end={22} start={5} delay={1} postFix="K+"/></span>
      <span>Students</span>
    </div>
    <div className="nbr">
      <span><Numbercounter end={4} start={1} delay={1} postFix="K+"/></span>
      <span>Courses</span>
    </div>
    <div className="nbr">
      <span><Numbercounter end={120} start={50} delay={1}  postFix="+"/></span>
      <span>Countries</span>
    </div>
  </div>
  </div>
</div>
  </>
  )
}

export default Aboutcart