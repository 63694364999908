import React from 'react'
import { coursesCard } from "../../Data/Course"
import './Course.css'

const CourseCart = () => {
  return (
    <>
    <div className="course">
        <h1>We provide Many types of course</h1>
        <p>Price pattern glossy waistline ensemble trend pumps elegant petticoat sewing pretporter value young availability original glossy handbag influence</p>
        <div className="categorie">
            <button>Design</button>
            <button>Popular</button>
            <button>Marketing</button>
            <button>Developent</button>
        </div>
        <div className='Courses'>
              {coursesCard.map((val) => {
                return (
                  <div className='cour'>
                    <div className='pict1'>
                      <img src={val.cover} alt='' />
                    </div>
                    <div className='courinfo'>
                      <h2>{val.coursesName}</h2>
                      <p>{val.desc}</p>
                      <div className='Last'>
                      <a>{val.priceAll}</a>
                      <button>Join Now</button></div>
                    </div>
                  </div>
                )
              })}
        </div>
    </div>
    
    
    
    </>
  )
}

export default CourseCart