import React from 'react'
import './Home.css'
import image from'../../assest/5851035.png'


import image1 from'../../assest/BigCommerce.png'
import image2 from'../../assest/Shopify.png'
import image3 from'../../assest/ShopifyPlus.png'
import image4 from'../../assest/WooCommerce.png'
import Aboutcart from '../About/Aboutcart'
import CourseCart from '../Course/CourseCart'
import Instructor from '../Instructor/Instructor'
const Home = () => {
  return (
    <>
    <div className="home">
<div className="title">
    <h1>Find The Best Online Course & Learn</h1>
    <p>Price pattern glossy aistline ensemble trend pumps elegant glossy handbag influence outfit braiding runway.</p>
    <div className="click">
        <button className='but1'>Check Courses</button>
        <button className='but2'>Learn More</button>
    </div>
    
</div>
<div className="image">
        <img src={image} />
    </div>
    </div>
    <div className="partner">
        <h1>Trusted By Many Camoanies Worldwide</h1>
        <div className="photo">
            <img src={image1} />
            <img src={image2} />
            <img src={image3} />
            <img src={image4} />
        </div>
    </div>
    <Aboutcart />
    <CourseCart />
    <Instructor />
    </>
  )
}

export default Home