import img1 from '../assest/t1.jpg'
import img2 from '../assest/t2.jpg'
import img3 from '../assest/t3.jpg'


export const team = [
    {
      cover: img1,
      name: "Adrian Molises",
      work: "DEVELOPER AND LEAD INSTRUCTOR",
      student:"23,290 student",
      etoile:"(5.0)",
      experince:"75+Course",
    },
    {
      cover: img2,
      name: "Arthur MaGregor",
      work: "DEVELOPER AND LEAD INSTRUCTOR",
      student:"80,070 student",
      etoile:"(5.0)",
      experince:"35+Course",
    },
    {
      cover: img3,
      name: "Anna Hanzen",
      work: "DEVELOPER AND LEAD INSTRUCTOR",
      student:"53,000 student",
      etoile:"(5.0)",
      experince:"45+Course",
    },
    
  ]