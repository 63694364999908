import img1 from '../assest/icons8-storytelling-96.png'
import img2 from '../assest/icons8-diploma-96.png'
import img3 from '../assest/icons8-initiate-money-transfer-96.png'
export const homeAbout = [
    {
      id: 1,
      cover: img2,
      title: "Online Courses",
      desc: "Strategy direct mailing crowdfunding product management. Social Media stealth",
    more:"Learn More"
    },
    {
      id: 1,
      cover: img1,
      title: "Expert Tutoring",
      desc: "Strategy direct mailing crowdfunding product management. Social Media stealth.",
      more:"Learn More"
    },
    {
      id: 1, 
      cover: img3,
      title: "Effective Methods",
      desc: "Strategy direct mailing crowdfunding product management. Social Media stealth",
      more:"Learn More"
    },
  ]